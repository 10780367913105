import { graphql } from 'gatsby'
import React from 'react'
import IndexPage from '../components/pages/Index'
import SEO from '../components/SEO'
import Layout from '../layouts/Main'

const Index = ({ data }) => <Layout>
    <SEO title='Home' canonical={`${data.site.siteMetadata.siteUrl}`} />
    <IndexPage {...data.strapiHomepage} />
  </Layout>

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiHomepage {
      ZooHero {
				title
      }
    }
  }
`

export default Index
