import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import PostCard from '../../../Blog/components/PostCard'

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 30px 20px 0;
  color: #fff;
  font-size: 1.6rem;
  background-image: linear-gradient(
  90deg
  ,#7D1C48,#D81F75);
  padding-top: 1px;
  padding-bottom: 50px;
`

const Title = styled.h2`

`

const Content = styled.div`
  margin: 0 auto;
  max-width: 1100px;
`

const Posts = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat( auto-fill, minmax(350px, 1fr) );
`

const BlogHero = () => {
  const { allStrapiBlogPosts } = useStaticQuery(graphql`
  {
    allStrapiBlogPosts(skip: 0, limit: 3, sort: { fields: [originalPublishDate, published_at], order: DESC }) {
      edges {
        node {
          slug
          title
          excerpt
          originalPublishDate
          published_at
          thumbnail {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 450)
                }
              }
            }
            author {
              slug
              fullName
              avatar {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, width: 100)
                  }
                }
              }
            }
        }
      }
    }
  }
  `)

  return <Wrapper>
    <Content>
      <Title>Night Zookeeper Blog</Title>
      <Posts>
      {
        allStrapiBlogPosts.edges.map(({ node }) => <PostCard key={node.slug} {...node} />)
      }
      </Posts>
    </Content>
  </Wrapper>
}

export default BlogHero 
