import React from 'react'
import { ParentsHeroDefault } from '../../Strapi/ParentsHero'
import ZooHero from '../../Strapi/ZooHero'
import TeachersHero from '../../Strapi/TeachersHero'
import BooksHero from '../../Strapi/BooksHero'
import ShopHero from '../../Strapi/ShopHero'
import PartnersHero from '../../Strapi/PartnersHero'
import BlogHero from './components/BlogHero'

interface IProps {
  ZooHero: {
    title: string
  }
}

const IndexPage = (props: IProps) => <>
    <ZooHero {...props.ZooHero} />
    <ParentsHeroDefault />
    <TeachersHero />
    <BooksHero />
    <ShopHero />
    <PartnersHero />
    <BlogHero />
  </>

export default IndexPage
